<template>
  <LogIn />
</template>

<script>
import LogIn from "@/components/Welcome/LogIn.vue";

export default {
  name: "WelcomeView",
  components: {
    LogIn,
  },
};
</script>

