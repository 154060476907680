<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>

</script>

<style>
body {
  background-color: #e0e1dd;
}
</style>