// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBtWXBPI9YkOic1N-Iuq8O6F1WaXfEOMx0",
  authDomain: "wolfcampus-d04e6.firebaseapp.com",
  databaseURL: "https://wolfcampus-d04e6-default-rtdb.firebaseio.com",
  projectId: "wolfcampus-d04e6",
  storageBucket: "wolfcampus-d04e6.appspot.com",
  messagingSenderId: "559480684174",
  appId: "1:559480684174:web:d3b6d1ae7b338f15f513df",
  measurementId: "G-VLD1SL3J64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// Initialize Cloud Firestore and get a reference to the service
export const auth = getAuth(app);

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
}

export const db = getFirestore(app);
export const storage = getStorage(app);